import React, { useState, useEffect, useMemo, useRef } from 'react'
import Menu from '../chat/Menu'
import "./aicopilot.css"
import backup from "../../Images/backup.png"
import plus from "../../Images/plus1.png"
import send from "../../Images/send.png"
import bell from "../../Images/ai-bell.png"
import search from "../../Images/search-big.jpg"
import { Header } from '../../components/Header/Header'
import axios from "axios";
import { v4 as uuidv4 } from 'uuid'; // Importing the UUID function
import { toast } from "react-toastify";
import uploadlogo from "../../Images/after-upload.png"
import blueai from "../../Images/blue-ai.png"
import findImage from "../../Images/find_in_page.png"
import find from "../../Images/find.jpg"
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { FileSuccessfullyUploaded } from './FileSuccessfullyUploaded'
import AICopilotIcon from '../../Images/ai-copiot-icon.svg';
import UploadIcon from '../../Images/upload-icon.svg';
import summarizeIcon from '../../Images/summarizeText-icon.svg';
import arrowForward from '../../Images/arrow_forward.svg';
import InvoiceIcon from '../../Images/invoice-icon.svg';
import SpreadsheetIcon from '../../Images/spreadsheet-icon.svg';
import DocumentUploadModal from '../AI_Copilot/uploadDocument.jsx';
import arrowForward2 from '../../Images/arrow_forward-2.svg';
import UploadModal from "../Contract/UploadModal.jsx";
import ExplorePrompt from './explorePromptModal.jsx';
import ContractSearch from '../../components/searchContracts.jsx';
import SearchDocuments from '../../components/searchDocuments.jsx';
import SearchInvoice from '../../components/searchInvoices.jsx';
import { useInputField } from './InputFieldContext.js';
import PromptChat from './PromptChat.jsx'
import ChatHistory from './ChatHistory.jsx'



export const AIcopilot = ({ isMenuOpen }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [chatInput, setChatInput] = useState("");
  const [selectedDoc, setSelectedDoc] = useState(null); // Track selected document
  const [clickedCardIndex, setClickedCardIndex] = useState(null); // Tracks which card is clicked
  const [inputValue, setInputValue] = useState("");
  const [isChatActive, setIsChatActive] = useState(false);
  const [showContractSearch, setShowContractSearch] = useState(false); // Tracks if ContractSearch should be shown
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [clickedCards, setClickedCards] = useState([]);
  const [uploadFile, setUploadFiles] = useState(false);
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [apiResponse, setApiResponse] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [contractKey, setContractKey] = useState("")
  const [contractUrl, setContractUrl] = useState("")
  const [documentType, setdocumentType] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  // const [isModalOpen, setModalOpen] = useState(false);
  const [token, setToken] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const { appendButton } = useInputField(); // Access the shared state
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [finalResponse, setFinalResponse] = useState("");
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [selectedPromptSection, setSelectedPromptSection] = useState(null);
  const [displayName, setDisplayName] = useState("");
  // 1) Initialize from localStorage on mount 
  // 2) Whenever isPromptChatVisible changes, store it in localStorage

  const [isPromptChatVisible, setIsPromptChatVisible] = useState(() => {
    const savedValue = localStorage.getItem("isPromptChatVisible");
    return savedValue ? JSON.parse(savedValue) : false;
    // You can default to 'true' if you want the chat to be open by default.
  });

  useEffect(() => {
    // Save to localStorage whenever isPromptChatVisible changes
    localStorage.setItem("isPromptChatVisible", JSON.stringify(isPromptChatVisible));
  }, [isPromptChatVisible]);

  const [chatId, setChatId] = useState(() => {
    const savedChatId = localStorage.getItem("chatId");
    return savedChatId || null;
  });

  useEffect(() => {
    if (chatId) {
      localStorage.setItem("chatId", chatId);
    } else {
      localStorage.removeItem("chatId");
    }
  }, [chatId]);

  /// chat history 

  const [chatHistory, setChatHistory] = useState([]);


  // Fetch chat history
  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/copilot/chats`,
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        setChatHistory(response.data.chat_sessions);
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };

    fetchChatHistory();
  }, []);

  // Handle selecting a chat session
  const handleChatSelection = (chat_id) => {
    setChatId(chat_id);
    console.log("chat_id", chat_id);

    localStorage.setItem("chatId", chat_id);
  };

  // Handle prompt selection from modal
  const handlePromptSelection = (prompt, sectionName) => {
    setChatInput(prompt);
    setSelectedPromptSection(sectionName);
    setShowContractSearch(true);
  };

  const handleFocus = () => {
    setIsChatActive(true);
  };
    const handleBlur = () => {
    // Optionally, you can set the chat inactive on blur if the input is empty.
    if (chatInput.trim() === "") {
      setIsChatActive(false);
    }
  };
  // Function to handle card click
  const handleCardClick = (message, index) => {
    setChatInput(message);
    setIsChatActive(true)
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setClickedCardIndex(index);
    setShowContractSearch(true);

    // Determine the section based on the index
    if (index === 0) {
      setSelectedPromptSection("Contracts"); // Card 1 relates to Contracts
    } else if (index === 1) {
      setSelectedPromptSection("Invoices"); // Card 2 relates to Invoices
    } else {
      setSelectedPromptSection("Invoices"); // Card 2 relates to Invoices
    }
  };



  const handleInputChange = (e) => {
    const value = e.target.value;
    setChatInput(value);
    if (value.trim() === "") {
      setShowContractSearch(false); // Hide ContractSearch if input is empty
      setSelectedDoc(null);
    }
  };
  // Handle document selection
  const handleDocumentSelect = (doc) => {
    // console.log("dooccccc",doc);

    setContractKey(doc.key); // Store the selected document key
    setContractUrl(doc.url)
    setdocumentType(doc.document_type);
    // console.log("slecteted document key",doc.key);
    // console.log("slecteted document url",doc.url);
    // console.log("slecteted document typeee",doc.document_type);
    setSelectedDoc(doc); // Store the selected document
    setShowContractSearch(false); // Hide ContractSearch
  };



  useEffect(() => {
    const firstName = localStorage.getItem("firstName") || localStorage.getItem("first_name");
    const lastName = localStorage.getItem("lastname") || localStorage.getItem("last_name");
    const email = localStorage.getItem("email");

    setUser({
      firstName,
      lastName,
      email,
    });
  }, []);




  // const handleNewChat = async () => {


  //   // Add the user's message to chat history
  //   setChatHistory((prev) => [...prev, { sender: "user", message: chatInput }]);

  //   try {
  //     // Make an API call to fetch the response
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,

  //       { client_message: chatInput },
  //       {
  //         headers: {
  //           Authorization: `Token ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // console.log(response.data.chat_id);

  //     const newChatId = response?.data?.chat_id;
  //     setChatId(newChatId); // Store chatId in state
  //     console.log("Chat ID:", newChatId);

  //     toast.success("New Chat Started");
  //     const source = response?.data?.source || "unknown";
  //     const botMessage =
  //       response?.data?.data || "No response received from the server.";



  //     // Add the bot's message to the chat history, including the source
  //     setChatHistory((prev) => [
  //       ...prev,
  //       {
  //         sender: "system",
  //         message: `${botMessage} (Source: ${source})`,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.error("Error while fetching API response:", error);
  //     setChatHistory((prev) => [
  //       ...prev,
  //       {
  //         sender: "system",
  //         message: "Something went wrong. Please try again!",
  //       },
  //     ]);
  //   }

  // };


  // Function to initiate a new chat session

  const initiateNewChat = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/copilot/new-chat/`,
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.chat_id) {
        setChatId(response.data.chat_id);
        console.log("New chat ID:", response.data.chat_id);

      } else {
        throw new Error("Chat ID not received");
      }
    } catch (error) {
      console.error("Error initiating chat:", error);
      toast.error("Failed to start a new chat. Please try again.");
    }
  };

  const effectRun = useRef(false);
  const containerRef = useRef(null);
  const inputRef = useRef(null);


  useEffect(() => {
    if (!effectRun.current) {
      initiateNewChat();
      effectRun.current = true;
    }
  }, []);

  const handleNewChat = () => {
    setChatId(null);
    initiateNewChat();
    setIsPromptChatVisible(false);
    setChatInput('');
    setSelectedDoc(null);
    toast.success("New Chat Started")
  };


  const handleCopilotButton = () => {
    // setChatId(null);
    // initiateNewChat();
    // setIsPromptChatVisible(false);
    // setChatInput('');
    // setSelectedDoc(null);
    // window.location.reload();

  };
  const handleSendMessage = async () => {
    if (!chatInput.trim()) {
      toast.error("Please enter a prompt!");
      return;
    }

    setIsLoadingResponse(true);
    setIsPromptChatVisible(true);

    let wsUrl = "";
    let payload = {};

    try {
      if (!selectedDoc) {
        wsUrl = `${process.env.REACT_APP_WS_URL}/ws/qdrant-copilot-chat/${chatId}/?token=${localStorage.getItem("token")}`;
        payload = { client_message: chatInput }; // No-document scenario
      } else {
        wsUrl = `${process.env.REACT_APP_WS_URL}/ws/prompt-library-answer/${chatId}/?token=${localStorage.getItem("token")}`;
        payload = { document_key: contractKey, prompt: chatInput }; // Document scenario
      }

      const ws = new WebSocket(wsUrl);
      let accumulatedText = "";
      let finalReceived = false; // Track if final response was received

      ws.onopen = () => {
        console.log(`WebSocket connection established to ${wsUrl}`);
        ws.send(JSON.stringify(payload));
      };

      ws.onmessage = (event) => {
        const data = event.data;
        if (data.startsWith("data:")) {
          try {
            const jsonData = JSON.parse(data.replace("data: ", "").trim());

            if (jsonData.type === "progress" && jsonData.chunk) {
              accumulatedText += jsonData.chunk;
              setChatHistory((prev) => {
                const updatedHistory = [...prev];

                if (
                  updatedHistory.length === 0 ||
                  updatedHistory[updatedHistory.length - 1].sender !== "bot"
                ) {
                  updatedHistory.push({
                    id: Date.now(),
                    sender: "bot",
                    text: jsonData.chunk,
                    final: false,
                  });
                } else {
                  updatedHistory[updatedHistory.length - 1].text += jsonData.chunk;
                }

                return updatedHistory;
              });
            }

            if (jsonData.type === "final") {
              finalReceived = true; // Mark final response received

              const questionsArray = jsonData.data?.generated_questions
                ? jsonData.data.generated_questions.split(";").map((q) => q.trim())
                : [];

              setGeneratedQuestions(questionsArray);
              setFinalResponse(accumulatedText);

              setChatHistory((prev) => {
                const updatedHistory = [...prev];
                if (
                  updatedHistory.length > 0 &&
                  updatedHistory[updatedHistory.length - 1].sender === "bot"
                ) {
                  updatedHistory[updatedHistory.length - 1].final = true;
                }
                return updatedHistory;
              });

              ws.close();
            }
          } catch (error) {
            console.error("Error parsing streamed JSON data:", error, "Raw Data:", data);
            toast.error("Error processing response. Please try again.");
          }
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        toast.error("WebSocket connection error. Please try again.");

        // If WebSocket error occurs and no final response was received, inject fallback bot response
        if (!finalReceived) {
          setChatHistory((prev) => [
            ...prev,
            {
              id: Date.now(),
              sender: "bot",
              text: "I'm sorry, there was an error processing your request. Please try again.",
              final: true,
            },
          ]);
        }
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed");
        setIsLoadingResponse(false);

        // If WebSocket closed without final response, inject fallback bot message
        if (!finalReceived) {
          setChatHistory((prev) => [
            ...prev,
            {
              id: Date.now(),
              sender: "bot",
              text: "I'm sorry, the connection was lost. Please try again.",
              final: true,
            },
          ]);
        }
      };
    } catch (error) {
      console.error("Error initializing WebSocket:", error);
      toast.error("Failed to send prompt. Please try again.");
      setIsLoadingResponse(false);

      // If WebSocket failed to initialize, inject fallback bot message
      setChatHistory((prev) => [
        ...prev,
        {
          id: Date.now(),
          sender: "bot",
          text: "I'm sorry, I'm having trouble connecting at the moment. Please try again later.",
          final: true,
        },
      ]);
    }
  };







  useEffect(() => {
    // console.log("Progress bar updated:", progress);
  }, [progress]);



  const showPopup = (title, message) => {
    setPopupTitle(title);
    setPopupMessage(message);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    // console.log("stored token in parent",storedToken);

    if (storedToken) {
      setToken(storedToken);
    } else {
      console.error("Token is missing from localStorage");
    }
  }, []);


  const toggleModal = () => {
    if (!token) {
      showPopup("Session Expired", "Session expired. Please log in again.");
      return;
    }
    setIsModalOpen((prev) => !prev);
  };

  const handleUploadSuccess = () => {

    toggleModal();
  };

  const handleCloseModal = () => {
    toggleModal();

  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleChatHistory = () => {
    setIsOpen(!isOpen);
  };


  const handleSearch = () => {
    console.log("Search input:", searchInput); // Replace with your search logic
  };

  const filteredContracts = useMemo(() => {
    if (!files || files.length === 0) return [];
    return files.filter((file) => {
      if (!file?.pdf_name) return false;
      return file.pdf_name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }, [files, searchQuery]);
  const filteredData = useMemo(() => {
    const normalizedQuery = searchQuery
      .trim()
      .replace(/\s+/g, " ")
      .toLowerCase();

    if (!normalizedQuery) return [...folders, ...files];

    return [...folders, ...files].filter((item) =>
      (item.name || "").toLowerCase().includes(normalizedQuery)
    );
  }, [folders, files, searchQuery]);

  // Function that clears the clickedCardIndex if the click is outside and the chat input is empty
  const handleClickOutside = (event) => {
    // Check if the click happened outside of our container
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      // Only reset the active card index if the chat input is empty (after trimming whitespace)
      if (chatInput.trim() === "") {
        setClickedCardIndex(null);
      }
    }
  };

  // Attach the event listener to detect clicks outside of the container
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [chatInput]); // re-run effect if chatInput changes


  // Define your placeholder text (should match your textarea placeholder)
  const placeholderText = "How can I help? Type your question or choose a prompt";

  // 1) If user has typed something, calculate based on input length.
  //    Otherwise, base it on the placeholder text length.
  const computedWidth = chatInput.trim().length > 0
    ? chatInput.length * 10
    : placeholderText.length * 10;

  // 2) Enforce a minimum width (e.g., 200px) and a maximum width (e.g., 600px)
  const dynamicWidth = Math.min(530, Math.max(200, computedWidth));





  return (
    <div className='' >

      <header className="flex justify-between items-center w-full h-[70px] bg-[#fff] border border-black/10 pr-4">


        <button
          className="flex items-center ml-[200px] cursor-pointer bg-transparent border-none" onClick={handleCopilotButton}
        >

          <h2
            className="mr-4"
            style={{
              color: "#000",
              fontFamily: "Outfit, sans-serif",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            AI Copilot
          </h2>
        </button>

      </header>

      {/* Render Menu but hide it */}
      <div style={{ display: 'none' }}>
        <Menu isOpen={isMenuOpen} setDisplayName={setDisplayName} />
      </div>


      {isPromptChatVisible ? (
        <PromptChat
          documentKey={contractKey}
          documentUrl={contractUrl}
          documentType={documentType}
          prompt={chatInput}
          chatId={chatId}
          chatInput={chatInput}
          isLoadingResponse={isLoadingResponse}
          finalResponse={finalResponse || ""}  // Ensure a fallback value
          generatedQuestions={generatedQuestions || []}
          isMenuOpen={isMenuOpen}
          onNewChat={handleNewChat}
        />
      ) : (

        // <div className={`ai-container ${isMenuOpen ? 'menu-open' : ''}`} >
        //   <div className={`copilot-page ${isMenuOpen ? 'reduced-width' : ''}`} >
        <div className='upload-mainpage flex justify-center items-center' >
          {/* style={{
            background: 'linear-gradient(130.93deg, rgba(139, 85, 221, 0.44) 10.67%, rgba(187, 66, 174, 0.44) 85.46%)'
          }} */}
          <div className={`${isMenuOpen ? 'ml-12' : 'ml-0'} AI-CP-ChatHistoryContainer flex gap-4`}>
            <div className="relative group">
              <button
                className="w-[44px] h-[36px] rounded-[6px] border border-[rgba(0,0,0,0.1)] bg-white p-[8px_12px] gap-[8px] shadow-[1px_3px_6px_rgba(0,0,0,0.1),5px_10px_11px_rgba(0,0,0,0.09),11px_23px_15px_rgba(0,0,0,0.05),20px_41px_18px_rgba(0,0,0,0.01),31px_64px_20px_rgba(0,0,0,0)] flex items-center justify-center"
                onClick={handleNewChat}
              >
                <img src="/newChat-icon.svg" alt="New Chat Icon" className="w-4 h-4" />
              </button>

              {/* Tooltip Below */}
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 bg-black text-white text-xs rounded-lg shadow-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                Start New Chat
                {/* Upward Arrow */}
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-2 h-2 bg-black rotate-45"></div>
              </div>

            </div>


            {/* <button className="chat-historyBtn" onClick={toggleChatHistory}>
              <span>Chat History</span>
              <img src={arrowForward2} alt="Forward arrow" />
            </button> */}
            <ChatHistory isOpen={isOpen} chatHistory={chatHistory} onSelectChat={handleChatSelection} />
          </div>
          <div className={`${isMenuOpen ? 'ml-36' : 'ml-12'} mainAI-container `}>



            <div className="AI-Container">
              <div className="topContainer inline-flex flex-col justify-center items-center">


                <h6>
                  Hi{" "}
                  {displayName
                    .split(" ")
                    .map(
                      (name) => name.charAt(0).toUpperCase() + name.slice(1)
                    )
                    .join(" ")}
                </h6>


                <span>What Can I Help You With Today?</span>
                <p >
                  Type your request below with one of <br />these suggestions.
                </p>

              </div>

              <div className='secondContainer'>
                <div ref={containerRef} className="card_container" >
                  {/* Card 1 */}
                  <div
                    className={`card ${clickedCardIndex === 0 ? "active" : ""}`}
                    onClick={() => handleCardClick("Summarize the data from Contract", 0)}
                  >
                    <img src={summarizeIcon} alt="Summarize Icon" />
                    <p>Summarize the data from Contract</p>
                  </div>

                  {/* Card 2 */}
                  <div
                    className={`card ${clickedCardIndex === 1 ? "active" : ""}`}
                    onClick={() =>
                      handleCardClick("Extract payment terms from Invoice", 1)
                    }
                  >
                    <img src={InvoiceIcon} alt="Invoice Icon" />
                    <p>Extract payment terms from Invoice</p>
                  </div>

                  {/* Card 3 */}
                  <div
                    className={`card ${clickedCardIndex === 2 ? "active" : ""
                      }`}
                    onClick={() =>
                      handleCardClick(
                        "Extract Due date of the Invoice",
                        2
                      )
                    }
                  >
                    <img src={SpreadsheetIcon} alt="spreadsheet Icon" />
                    <p>Extract Due date of the Invoice</p>
                  </div>

                </div>
                <div className="explorePrompt">
                  <button onClick={() => setIsPromptModalOpen(true)}>
                    <span>Explore Prompts</span>
                    <img src={arrowForward} alt="Forward arrow" />
                  </button>
                  <ExplorePrompt
                    isOpen={isPromptModalOpen}
                    onClose={() => setIsPromptModalOpen(false)}

                    onSelectPrompt={handlePromptSelection}

                  />
                </div>

              </div>

              <div className='thirdContainer'>
                {/* Input and Send Button */}

                <div>
                  <button
                    onClick={() =>
                       {
                        setIsButtonClicked(true);
                        setIsModalOpen(true)
                      }
                      } // Opens the modal
                      className={`uploadButton border-gray-300 rounded-lg flex items-center space-x-2 p-2 ${isButtonClicked ? "active-shadow" : ""}`}
                      >
                    <span className="font-bold text-base">Upload</span>
                    <img src={UploadIcon} alt="Upload Icon" className="w-6 h-6" />
                  </button>

                  {/* Upload Modal */}
                  <DocumentUploadModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} type={"document"} />
                  {/* <UploadModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onUploadSuccess={handleUploadSuccess}
                    type={"document"}
                  /> */}
                </div>

                <div className="inputFieldWrapper">
                  <div
                    className={`AI-chat-input-section ${isChatActive || inputValue.trim() !== "" ? "active" : ""
                      }`}
                  >               


                    <div className="searchContent"
                     style={{
                      width: showContractSearch || selectedDoc ? "auto" : "100%",

                      alignItems: "center",
                    }}>
                      <textarea
                        ref={inputRef}
                        className="AI-chat-input resize-none overflow-auto "
                        placeholder="How can I help? Type your question or choose a prompt"
                        value={chatInput}
                        onChange={handleInputChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleSendMessage();
                          }
                        }}
                        rows={1} // Start with a single row
                        style={{
                          width: `${dynamicWidth}px`,
                          // minWidth: "0vh", // Minimum width
                          // maxWidth: "90vh", // Maximum width before scrollbar
                          maxHeight: "150px", // Limits height before scrollbar
                          // padding: "8px",
                          // border: "1px solid rgba(0, 0, 0, 0.5)",
                          borderRadius: "6px",
                          overflowY: "auto", // Enables vertical scrolling for long prompts
                          overflowX: "hidden", // Prevents horizontal scroll
                          transition: "width 0.2s ease-in-out", // Smooth expansion effect
                        }}
                      />
                      {/* Selected Document or ContractSearch */}
                      <div className="searchDocument flex items-center ml-2">
                        {selectedDoc ? (
                          <button
                            className="selected-doc-button flex items-center gap-2"
                            style={{
                              height: "32px",
                              background: "rgba(254, 249, 247, 1)",
                              border: "1px solid rgba(0, 0, 0, 0.5)",
                              borderRadius: "6px",
                              padding: "0 10px",
                              fontSize: "14px",
                              color: "#000",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => setShowContractSearch(true)}
                          >
                            <span>{selectedDoc.name}</span>
                            <span
                              className="ml-2 text-red-500 font-bold cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedDoc(null);
                                setContractKey("");
                                setShowContractSearch(true);
                              }}
                            >
                              ×
                            </span>
                          </button>
                        ) : (
                          chatInput.trim() &&
                          showContractSearch && (
                            <div className="ml-0">
                              {selectedPromptSection === "Documents" && <SearchDocuments onDocumentSelect={handleDocumentSelect} />}
                              {selectedPromptSection === "Contracts" && <ContractSearch onDocumentSelect={handleDocumentSelect} />}
                              {selectedPromptSection === "Invoices" && <SearchInvoice onDocumentSelect={handleDocumentSelect} />}
                            </div>
                          )
                        )}
                      </div>
                    </div>


                    {/* Send Button */}
                    <button className="chat-button ml-2" onClick={handleSendMessage}>
                      <img
                        src="/send.svg"
                        alt="send logo"
                        className="send-icon"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </button>
                  </div>
                </div>



              </div>
            </div>
          </div>


        </div>

        //   </div>
        // </div>
      )}
    </div >




  )
}