import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchChatContract = createAsyncThunk(
  "chatContract/fetchChatContract",
  async ({ contractKey, clientMessage }, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No authorization token found");
    }

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/qdrant-chat-contract/${contractKey}/?token=${token}`;
    console.log("WebSocket URL:", wsUrl);

    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log("WebSocket connection established for contract chat.");
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        try {
          const data = JSON.parse(event.data);
          console.log("Parsed WebSocket message data:", data);

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            console.log("Accumulated Answer:", accumulatedAnswer);
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.type === "final") {
            console.log("Final WebSocket message received:", data);
          
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            generatedQuestions = data.data.example_questions || ""; 
          
           
            
          
            // Extract user initials
            const firstChar = data.data.first_char || "";
            const lastChar = data.data.last_char || "";
            const userInitials = (firstChar + lastChar).trim().toUpperCase() || "N/A";
            
          
           
          
            // Dispatch data to Redux
            dispatch(updateFinalAnswer(accumulatedAnswer));
            dispatch(updateBotMessageInfo({ botMessageId, runId, generatedQuestions }));
          
            dispatch(updateStatus("succeeded"));
            socket.close();
            resolve(accumulatedAnswer);
          }
          
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed.");
      };
    });
  }
);

export const fetchContractSummary = createAsyncThunk(
  "chatContract/fetchContractSummary ",
  async ({ contractKey, clientMessage }, { dispatch, rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No authorization token found");
    }

    const wsUrl = `${process.env.REACT_APP_WS_URL}/ws/contract-summary-and-chats/${contractKey}/?token=${token}`;
    console.log("WebSocket URL:", wsUrl);

    const socket = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      let accumulatedAnswer = "";
      let botMessageId = null;
      let runId = null;
      let generatedQuestions = null;

      socket.onopen = () => {
        console.log("WebSocket connection established for contract chat.");
        dispatch(updateSummaryWebSocketStatus(true));
        socket.send(JSON.stringify({ client_message: clientMessage }));
        dispatch(updateStatus("loading"));
        dispatch(updatePartialAnswer(""));
        
      };

      socket.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        try {
          const data = JSON.parse(event.data);
          console.log("Parsed WebSocket message data:", data);

          if (data.chunk) {
            accumulatedAnswer += data.chunk;
            console.log("Accumulated Answer:", accumulatedAnswer);
            dispatch(updatePartialAnswer(accumulatedAnswer));
          }

          if (data.type === "final") {
            console.log("Final WebSocket message data:", data);
            dispatch(updateSummaryWebSocketStatus(false));
            botMessageId = data.data.bot_message_id;
            runId = data.data.run_id;
            const summary = data.data.summary; // Add this
            console.log("Summary received:", data.data.summary);

            dispatch(updateFinalSummary(summary));
            generatedQuestions = data.data.example_questions || "";
            console.log("questionssssssssssss", generatedQuestions);
            let formattedTimestamp = "N/A";  // Default fallback

            if (data.data.summary_generated_at) {
              try {
                formattedTimestamp = new Date(data.data.summary_generated_at).toLocaleString("en-US", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                });
              } catch (error) {
                console.error("Error parsing summary_generated_at:", error);
              }
            }
            
          
            // Extract user initials
            const firstChar = data.data.first_char || "";
            const lastChar = data.data.last_char || "";
            const userInitials = (firstChar + lastChar).trim().toUpperCase() || "N/A";
            
          
            console.log("Formatted Timestamp (Processed):", formattedTimestamp);
            console.log("User Initials (Processed):", userInitials);
          
            dispatch(updateGeneratedQuestions(generatedQuestions)); // Update the state with the summary
            dispatch(
              updateBotMessageInfo({ botMessageId, runId, generatedQuestions })
            );
            dispatch(updateSummaryMetadata({ formattedTimestamp, userInitials }));

            dispatch(updateStatus("succeeded"));
            socket.close();
            resolve(accumulatedAnswer);
          }
        } catch (error) {
          console.error("Error parsing WebSocket message:", event.data, error);
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(rejectWithValue("WebSocket connection error"));
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed.");
      };
    });
  }
);

const chatContractSlice = createSlice({
  name: "chatContract",
  initialState: {
    messages: [],
    partialAnswer: "",
    finalAnswer: "",
    partialSummary: "",
    finalSummary: "", 
    status: "idle",
    summaryStatus: "idle",
    error: null,
    summaryError: null,
    botMessageId: null,
    runId: null,
    generatedQuestions: null,
    feedback: {},
    formattedTimestamp: "",
    userInitials: "",
    isSummaryWebSocketActive: true,
  },

  reducers: {
    resetChatContractState: (state) => {
      state.messages = [];
      state.partialAnswer = "";
      state.finalAnswer = "";
      state.status = "idle";
      state.summaryStatus = "idle";
      state.error = null;
      state.botMessageId = null;
      state.runId = null;
      state.generatedQuestions = null;
      
    },
    updatePartialAnswer: (state, action) => {
      state.partialAnswer = action.payload;
    },
    updateFinalAnswer: (state, action) => {
      state.finalAnswer = action.payload;
    },
    updateBotMessageInfo: (state, action) => {
      const { botMessageId, runId, generatedQuestions } = action.payload;
      state.botMessageId = botMessageId;
      state.runId = runId;
      state.generatedQuestions = generatedQuestions;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    updateGeneratedQuestions: (state, action) => {
      state.generatedQuestions = action.payload;
    },
    updatePartialSummary: (state, action) => {
      state.partialSummary = action.payload;
    },
    updateFinalSummary: (state, action) => {
      state.finalSummary = action.payload;
    },
    updateSummaryStatus: (state, action) => {
      state.summaryStatus = action.payload;
    },
    updateSummaryMetadata: (state, action) => {
      console.log("Updating Redux: Summary Metadata", action.payload);
    
      if (action.payload) {
        state.formattedTimestamp = action.payload.formattedTimestamp ? action.payload.formattedTimestamp : "N/A";
        state.userInitials = action.payload.userInitials ? action.payload.userInitials : "N/A";
      }
    },
    
    updateSummaryWebSocketStatus: (state, action) => {
      state.isSummaryWebSocketActive = action.payload;
    },
    
  
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChatContract.fulfilled, (state, action) => {
        state.messages.push({
          answer: action.payload,
          botMessageId: state.botMessageId,
          runId: state.runId,
        });
        state.status = "succeeded";
      })
      .addCase(fetchContractSummary.pending, (state) => {
        state.summaryStatus = "loading";
      })
      .addCase(fetchContractSummary.fulfilled, (state) => {
        state.summaryStatus = "succeeded";
      })
      .addCase(fetchContractSummary.rejected, (state, action) => {
        state.summaryError = action.payload;
        state.summaryStatus = "failed";
      })
      .addCase(fetchChatContract.rejected, (state, action) => {
        state.error = action.payload;
        state.status = "failed";
      });
  },
});

export const {
  resetChatContractState,
  updatePartialAnswer,
  updatePartialSummary,
  updateFinalSummary,
  updateSummaryStatus,
  updateGeneratedQuestions,
  updateFinalAnswer,
  updateBotMessageInfo,
  updateStatus,
  updateSummaryMetadata,
  updateSummaryWebSocketStatus
} = chatContractSlice.actions;
export default chatContractSlice.reducer;
